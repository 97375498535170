<template>
  <div>
    <v-card cols="12">
      <v-toolbar title="Plazos" flat>
        <v-icon left>mdi-cash-clock</v-icon>
        <v-toolbar-title>Plazos</v-toolbar-title>
        <v-divider vertical class="mx-4"></v-divider>
        <v-btn small color="green" dark @click="crearPlazo"
          ><v-icon>mdi-plus</v-icon>Crear plazo</v-btn
        >

        <v-col cols="3" class="mt-4">
          <v-autocomplete
            label="Formas de pago"
            v-model="paymentMethodTypeId"
            :items="paymentMethodTypes"
            item-text="description"
            item-value="payment_method_type_id"
            clearable
            @change="cargarListaPlazoPago()"
          ></v-autocomplete>
        </v-col>
      </v-toolbar>

      <v-data-table
        :items="listaPlazosPago"
        :headers="headersPlazoPago"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn
            title="editar"
            @click="editarPlazo(item)"
            small
            icon
            color="green"
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >

          <v-btn
            title="formas pago"
            @click="abrirDialogoFormaPago(item)"
            small
            icon
            color="green"
            ><v-icon small>mdi-dots-horizontal</v-icon></v-btn
          >
          <span v-if="item.total_fp > 0">{{ item.total_fp }} </span>
        </template>
        <template v-slot:item.activo="{ item }">
          {{ item.activo }}
          <v-icon
            :color="item.activo == 'S' ? 'green' : 'grey'"
            class="mx-2"
            @click="activarPlazoPago(item)"
            >{{ getIcono(item) }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOGO PLAZO -->
    <v-dialog v-model="dialogPlazoPago" persistent width="700">
      <v-form ref="formPlazoPago">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Plazo
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogPlazoPago = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.plazo_id"
                label="Codigo"
                :disabled="editarPlazoPago"
              ></v-text-field>
            </v-col>


            <!-- <v-col md="6" class="py-0 my-0">
              <v-select 
                v-model='datosPlazoPago.product_store_id'
                :items=productStores 
                :item-text="item => `${item.store_name} - ${item.company_name}`"
                item-value="product_store_id" 
                label="Tienda"
                
                dense 
                style="margin-top:22px" 
              />
            </v-col> -->

            
            <v-col md="6" class="py-0 my-0">
              <v-select 
                v-model='datosPlazoPago.aprueba_linea_credito'
                :items=aprobacionLineaCredito 
                label="Tipo de aprobación de línea de crédito"
                dense 
                style="margin-top:22px" 
              />
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.dias"
                label="Días"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0 mb-4">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.num_cuotas"
                label="Numero de cuotas"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.entrada"
                label="% de Entrada"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0 mb-4">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.dias_gracia"
                label="Días de gracia"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.sec"
                label="Secuencia"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0 mb-4">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlazoPago.dias_cuota"
                label="Días entre cuotas"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarPlazo">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogPlazoPago = false">
              <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- FORMAS DE PAGOS ASOCIADAS CON EL PLAZO -->
    <v-dialog v-model="dialogFormaPago" width="600">
      <v-form ref="formFormaPagoPlazo">
        <v-card class="pb-2">
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-cash-multiple</v-icon>
            <v-toolbar-title
              >Formas de pago asociadas con ( {{ plazoSel }} )</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn small text @click="cerrarDialogFormaPago">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-toolbar flat>
            <v-col cols="6">
              <v-select
                label="Formas de pago"
                v-model="paymentMethodTypeId"
                :items="paymentMethodTypes"
                item-text="description"
                item-value="payment_method_type_id"
              ></v-select>
            </v-col>

            <v-btn color="primary" small @click="agregarFormaPago" class="mx-4">
              <v-icon>mdi-plus</v-icon>Agregar
            </v-btn>
          </v-toolbar>

          <v-row class="mt-1 mx-2 pt-0 mt-0 pb-6 mb-0">
            <v-col md="12" class="py-0 my-0">
              <v-data-table
                :items="listaFormasPago"
                :headers="headersFormasPago"
                hide-default-footer
              >
                <template v-slot:item.opciones="{ item }">
                  <v-btn @click="borrarFormaPagoPlazo(item)" small icon
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "TipoPlazo",

  data: () => ({
    show: false,
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,
    overlay: false,
    plazosMetodoPago: [],
    required: [(v) => !!v || "El campo es requerido"],
    headersPlazoPago: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "Descripcion", align: "start", value: "descripcion" },
      { text: "Dias de plazo", align: "start", value: "dias" },
      { text: "Num. cuotas", align: "start", value: "num_cuotas" },
      { text: "Entrada", align: "start", value: "entrada" },
      { text: "Secuencia", align: "start", value: "sec" },
      { text: "Activo", align: "start", value: "activo" },
    ],
    listaPlazosPago: [],
    datosPlazoPago: {},
    productStores: [],
    headersFormasPago: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "Forma de pago", align: "start", value: "description" },
    ],
    listaFormasPago: [],
    datosFormaPago: {},
    dialogPlazoPago: false,
    dialogFormaPago: false,
    paymentMethodTypeId: "",
    plazoId: "",
    plazoSel: "",
    paymentMethodTypes: [
      { 
        payment_method_type_id: "EFT_ACCOUNT", description: "Transferencia" 
      },
      { 
        payment_method_type_id: "CREDITO", description: "Crédito" 
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF_INT", description: "Trajeta de crédito diferido con intereses",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF", description: "Trajeta de crédito diferido sin intereses",
      },
      {
        payment_method_type_id: "DEBIT_CARD", description: "Tarjeta de débito",
      },
      {
        payment_method_type_id: "CREDIT_CARD", description: "Tarjeta de crédito corrente",
      },
      { 
        payment_method_type_id: "CASH", description: "Efectivo" 
      },
      { 
        payment_method_type_id: "PERSONAL_CHECK", description: "Cheque" 
      },
      { 
        payment_method_type_id: "PLACE_TO_PAY_DC", description: "Pago placetopaytarjeta débito" 
      },
      { 
        payment_method_type_id: "PLACE_TO_PAY_CC", description: "Pago placetopay tarjeta crédito" 
      },
    ],
    aprobacionLineaCredito: ['MANUAL','AUTOMÁTICO'],
    editarPlazoPago: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {

    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    ...mapActions("access", []),

    cargarListaPlazoPago() {
    
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago");
      this.requestApi({
        method: "GET",
        data: {
          accion: "listaPlazosPago",
          paymentMethodType: this.paymentMethodTypeId,
        },
      })
        .then((res) => {
          console.log(res);
          this.listaPlazosPago = res.data.detail.plazo_pago
          this.productStores = res.data.detail.product_stores
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    cargarDatosPlazoPago(plazoId) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago/" + plazoId);
      this.requestApi({
        method: "POST",
        data: {
          accion: "datosPlazoPago",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.datosPlazoPago = res.data.detail.plazo_pago;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    cargarFormasPagoPlazo(plazoId) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago/" + plazoId);
      this.requestApi({
        method: "GET",
        data: {
          tipo_dato: "datosFormaPagoPlazo",
        },
      })
        .then((res) => {
          console.log(res);
          this.listaFormasPago = res.data.detail;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    abrirDialogoPlazo() {
      this.dialogPlazoPago = true;
    },

    editarPlazo(item) {
      this.editarPlazoPago = true;
      this.cargarDatosPlazo(item.plazo_id);
      this.abrirDialogoPlazo();
    },

    crearPlazo() {
      this.datosPlazoPago = {};
      this.editarPlazoPago = false;
      this.abrirDialogoPlazo();
    },

    cargarDatosPlazo(plazoId) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago/" + plazoId);
      this.requestApi({
        method: "GET",
        data: {
          tipo_dato: "datosPlazoPago",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.datosPlazoPago = res.data.detail;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    guardarPlazo() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago");
      this.requestApi({
        method: "POST",
        data: {
          accion: this.editarPlazoPago === true ? "modificarPlazoPago" : "crearPlazoPago",
          datos_plazo: this.datosPlazoPago,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.dialogPlazoPago = false;
          this.cargarListaPlazoPago();
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    abrirDialogoFormaPago(item) {
      this.plazoId = item.plazo_id;
      this.paymentMethodTypeId = "";
      this.plazoSel = item.descripcion;
      this.cargarFormasPagoPlazo(item.plazo_id);
      this.dialogFormaPago = true;
    },

    agregarFormaPago() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago");
      this.requestApi({
        method: "POST",
        data: {
          accion: "agregarFormaPagoPlazo",
          forma_pago: {
            payment_method_type_id: this.paymentMethodTypeId,
            plazo_id: this.plazoId,
          },
        },
      })
        .then((res) => {
          console.log(res.data);
          this.cargarFormasPagoPlazo(this.plazoId);
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    borrarFormaPagoPlazo(item) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago");
      this.requestApi({
        method: "POST",
        data: {
          accion: "borrarFormaPagoPlazo",
          payment_method_type_id: item.payment_method_type_id,
          plazo_id: item.plazo_id,
        },
      })
        .then((res) => {
          this.cargarFormasPagoPlazo(item.plazo_id);
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    handlePageChange() {
      this.cargarListaPlazoPago();
    },

    getIcono(item) {
      if (item.activo == "S") {
        return "mdi-toggle-switch";
      } else {
        return "mdi-toggle-switch-off";
      }
    },

    activarPlazoPago(item) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("plazo-pago");
      this.requestApi({
        method: "POST",
        data: {
          accion: "cambiarEstadoPlazoPago",
          activo: item.activo == "S" ? "N" : "S",
          plazo_id: item.plazo_id,
        },
      })
        .then((res) => {
          this.cargarListaPlazoPago();
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    cerrarDialogFormaPago() {
      this.dialogFormaPago = false;
      this.cargarListaPlazoPago();
      this.listaPlazosPago = []
      this.plazoId = "";
    }

  },
  mounted() {    
    this.setTitleToolbar("TERMINOS Y PLAZOS");
    this.cargarListaPlazoPago();
  },
};
</script>